import React from 'react';
import { Link, graphql } from 'gatsby';
import marked from 'marked';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import Button from '../components/Button';

import css from './community-single.module.scss';
import ql from '../images/quote-mark-left.svg';
import qr from '../images/quote-mark-right.svg';
import iconInstagram from '../images/icon-instagram-grey.svg';
import iconFacebook from '../images/icon-facebook-grey.svg';
import iconTwitter from '../images/icon-twitter-grey.svg';
import iconHeart from '../images/icon-heart-grey.svg';
import iconBack from '../images/ic_arrow_back_24px.svg';
import iconNext from '../images/ic_arrow_next_24px.svg';

const CommunitySingle = ({ data, pageContext: { prev, next } }) => (
  <Layout name="community-single">
    <SEO title={`${data.strapiResource.title} | WAYE Talks`} keywords={[`WAYE`, `WAYE Talks`]} />

    <Pane name="landing">
      {data.strapiResource.coverPhoto && 
      <Hero name="abt-detail" img_src={data.strapiResource.coverPhoto.url} />
      }

      <Container className={css.bodyTextGroup}>
        <Row className={css.bodyTextGroup__row}>
          <Col xs="10" xs-offset="1" sm="6" sm-offset="1" className={css.bodyTextGroup__col}>
            <h1>
              {data.strapiResource.title}
            </h1>
          </Col>
          <Col xs="10" xs-offset="1" sm="3" sm-offset="1" className={css.bodyTextGroup__col}>
            <h2>
              <span>{data.strapiResource.author}</span>
              <small>{data.strapiResource.date}</small>
            </h2>
          </Col>
        </Row>
      </Container>
    </Pane>

    <Pane name="content-body" className={css.contentBody}>
      <Container>
        <Row>
          <Col xs="10" xs-offset="1" xs="10" xs-offset="1" sm="10" sm-offset="1" className={css.abt__body}>
            <div dangerouslySetInnerHTML={{__html: marked(data.strapiResource.body)}}></div>
          </Col>
        </Row>

        {/*
        <Row>
          <Col sm="10" sm-offset="1" className={css.abt__share}>
            <ul>
              <li>
                <a href="#">
                  <img src={iconInstagram} alt="Instagram" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={iconFacebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src={iconTwitter} alt="Twitter" />
                </a>
              </li>
              <li className={css.divider}>
                <div></div>
              </li>
              <li>
                <a href="#">
                  <img src={iconHeart} alt="Like" />
                  <span>4 Likes</span>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        */}

        <Row className={css.paginationGroup}>
          <Col xs="12" sm="10" sm-prepend className={css.pagination__prev}>
            <Row relative={true}>
              <Col sm="3" sm-offset="1">
                {prev &&
                <Link to={`/community/${prev.slug || prev.id}`}>
                  <img src={iconBack} alt="" />
                  {prev.title}
                </Link>
                }
              </Col>
            </Row>
          </Col>
          <Col xs="12" sm="5" sm-prepend className={css.pagination__divider}>
            <hr />
          </Col>
          <Col xs="12" sm="10" sm-prepend className={css.pagination__next}>
            <Row relative={true}>
              <Col xs="12" sm="3" sm-offset="2" className={css.pagination__nextCol}>
                {next &&
                <Link to={`/community/${next.slug || next.id}`}>
                  {next.title}
                  <img src={iconNext} alt="" />
                </Link>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Pane>
  </Layout>
);

/**
 * expose
 */

export default CommunitySingle;

export const query = graphql`
  query CommunitySingle($id: String) {
    strapiResource(id: {eq: $id}) {
      type
      title
      author
      date(formatString: "MMM, D YYYY")
      body
      coverPhoto {
        url
      }
      updatedAt
      slug
    }
  }
`
