import React from 'react';
import { fm } from '../utils/fm.js';

import { Container, Row, Col } from './react-grid';
import css from './Hero.module.scss';

const Hero = (props) => {
  let { name,
    h1,
    h1_append,
    logo,
    logo_alt,
    tagline,
    tagline_source,
    img_src} = props;

  let style = {backgroundImage: `url(${img_src})`};

  return (
    <div className={`${css.hero} ${css[fm('hero--'+name)]}`}>
      <div className={css.hero__overlay}>
        <Container className={css.hero__overlayContainer}>
          <Row className={css.hero__overlayRow}>
            <Col xs="10" xs-offset="2" sm="5" sm-offset="1" className={css.hero__header}>
              {h1 && <h1>{h1}</h1>}
              <img src={logo} alt={logo_alt} />
              {h1_append && <span className={css.h1__append}>{h1_append}</span>}
            </Col>
            {tagline &&
            <Col xs="11" xs-offset="1" sm="6" sm-offset="0" sm-extend className={css.hero__tagline}>
              <span>{tagline}</span>
              {tagline_source &&
              <strong className={css.tagline__source}>{tagline_source}</strong>}
            </Col>}
          </Row>
        </Container>
      </div>
      <Container className={css.hero__imageContainer}>
        <Row>
          <Col xs="10" nogutter className={css.hero__image}>
            <div className={css.heroImage} style={style}></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

/**
 * expose
 */

export default Hero;
